@CHARSET 'UTF-8' {}

@import 'variables';
@import 'mixins';


[class^="fi-"]::before,
[class*=" fi-"]::before {
    margin-right: 0;
    margin-left: 0; }


@import 'master';




html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block; }
body {
	line-height: 1; }
ol, ul {
	list-style: none; }
blockquote, q {
	quotes: none; }
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none; }
table {
	border-collapse: collapse;
	border-spacing: 0; }

// CSS reset end

body {
	background-image: url(../images/wrapper/bg.jpg);
	background-repeat: no-repeat;
	font-family: 'Roboto Slab', serif;
	background-attachment: fixed;
	// background: #e1819d
	// background: -webkit-linear-gradient(left, #e1819d , #30a0b8)
	// background: -o-linear-gradient(right, #e1819d, #30a0b8)
	// background: -moz-linear-gradient(right, #e1819d, #30a0b8)
 }	// background: linear-gradient(135deg, #e1819d , #30a0b8)
header {
	#navbar {
		background-image: none;
		border: none;
		background-color: transparent;
		box-shadow: none;
		color: white;
		ul.navbar-nav {
			display: table;
			width: 100%;
			margin: 30px 0;
			> li {
				display: table-cell;
				float: none;
				position: relative;
				> a {
					color: white;
					white-space: nowrap;
					border-top: 2px solid transparent;
					&:hover {
						border-top: 2px solid $secondaryColor;
						color: $secondaryColor;
						transition: 0.5s;
						-webkit-transition: 0.5s; } }
				ul {
					display: none;
					position: absolute;
					background-color: white;
					z-index: 99;
					box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);
					font-size: 0.85em;
					line-height: 2em;
					text-align: left;
					li {
						padding: 10px;
						&:hover {
							background-color: #edf9fd; }
						a {
							white-space: nowrap;
							color: black;
							&:hover {
								text-decoration: none; } } } }
				&:hover {
					ul {
						display: block;
						li {
							display: block; } } } }
			@media screen and (max-width: 1365px) and (min-width: 1300px) {
				position: relative;
				right: 100px; }
			.active {
				background-image: none;
				background-color: transparent;
				box-shadow: none;
				a {
					background-color: transparent;
					box-shadow: none;
					background-image: none;
					border-top: 2px solid white; } }

			.submenu {
				background-image: url(../images/icons/arrow_down_white.svg);
				background-position: center 41px;
				background-repeat: no-repeat;
				background-size: 0.8em; } } }

	#logo {
		background-image: url(../images/icons/logo.svg);
		background-repeat: no-repeat;
		width: 250px;
		height: 50px;
		background-size: contain;
		padding: 0;
		position: relative;
		top: 35px;
		left: 35px;
		@media screen and (max-width: 1365px) {
			width: 180px; } }
	.member_of {
		display: block;
		width: 220px;
		height: 85px;
		background-image: url(../images/icons/ribbon.svg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		top: 110px;
		position: absolute;
		left: 0; }
	ul.contact, ul.contact-mobile {
		margin-top: 35px;
		padding-right: 30px;
		a {
			color: $secondaryColor;
			display: block;
			text-align: right;
			margin-bottom: 12px;
			font-weight: 700;
			&:hover {
				color: white;
				text-decoration: none; } } }
	ul.contact {
		position: absolute;
		right: 25px;
		@media screen and (max-width: 1400px) and (min-width: 1301px) {
			right: 0; } }
	ul.contact-mobile {
		padding-right: 30px;
		position: absolute;
		z-index: 99;
		text-align: center;
		width: 100%;
		padding: 0;
		margin: 0 -30px;
		top: 300px;
		display: none;
		li {
			a {
				text-align: center; } } } }
.admin-edit {
	position: relative;
	//border: 1px dashed transparentize($warningColor, 0.75)
	//background-color: transparentize(white, 0.95)
	overflow: hidden;
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		border: 1px solid transparentize(darken($warningColor, 30), 0.75);
		background-color: transparentize(black, 0.95);
		&:hover, &:focus {
			display: none; } }
	&:hover {
		border: 1px solid darken($errorColor, 10);
		background-color: transparentize(white, 0.33);
		color: darken($errorColor, 20);
		cursor: url(../images/pencil-red-16.gif) 0 16, pointer; }
	&[data-variant="static_content"] {
		display: inherit;
		&:hover, &:focus {
			cursor: url(../images/doc-edit-16.gif) 0 16, pointer; } } }
.menu-content-edit {
	@extend .admin-edit;
	display: inherit;
	&:hover, &:focus {
		cursor: url(../images/doc-edit-16.gif) 0 16, pointer; } }
main {
	min-height: 590px !important;
	.main-bar {
		h1 {
			color: white;
			font-size: 3em;
			margin-bottom: 0.3em; } }
	.content-web {
		background-color: white;
		border-radius: 4px;
		font-weight: 100;
		padding-top: 20px;
		margin-bottom: 100px;
		p, ul, ol {
			padding: 0 20px;
			margin-bottom: 15px;
			line-height: 1.5em; }
		ul, ol {
			padding-left: 35px;
			list-style-type: initial; }
		h3 {
			padding: 0 20px;
			font-size: 1.5em;
			margin-bottom: 0.5em; }
		.available-courses {
			background-color: #edf9fd;
			display: block;
			padding: 15px 20px;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			margin-top: 30px;
			a {
				color: $primaryColor;
				font-weight: 700;
				i {
					height: 15px;
					width: 15px;
					display: inline-block;
					background-image: url(../images/icons/arrow_right_blue.svg);
					background-repeat: no-repeat;
					position: relative;
					left: 5px;
					top: 2px; }
				&:hover {
					text-decoration: none;
					color: #d8779a; } } } }
	.ask-her {
		background-color: $primaryColor;
		border-radius: 6px;
		text-align: center;
		color: white;
		margin-bottom: 70px;
		h3 {
			font-size: 1.5em;
			padding: 20px 30px; }
		.order-btn {
			margin: 15px auto; }
		.underlined-span {
			text-decoration: none;
			padding-bottom: 25px;
			display: block;
			a {
				text-decoration: none;
				color: white;
				&:hover {
					color: $secondaryColor;
					-webkit-transition: 0.2s;
					transition: 0.2s; }
				span {
					text-decoration: none; } } } } }


.float-left {
	float: left; }
.float-right {
	float: right; }
.clear {
	clear: both; }
.order-btn {
	background-color: $secondaryColor;
	border: 6px solid white;
	border-radius: 100px;
	width: 200px;
	height: 72px;
	a {
		color: #fff;
		display: block;
		text-align: center;
		height: 60px;
		border-bottom: 4px solid #dc9037;
		padding: 18px;
		border-radius: 100px;
		-webkit-transition: 0.2s;
		transition: 0.2s;
		cursor: pointer;
		font-weight: 700;
		font-size: 1.3em;
		&:hover, &:focus {
			text-decoration: none;
			border-bottom: 1px solid #dc9037;
			padding: 21px; } }
	&:hover {
		background-color: $primaryColor;
		-webkit-transition: 0.2s;
		transition: 0.2s; } }
.navbar-default {
	.navbar-toggle {
		border: none;
		margin: 0;
		padding: 0;
		top: 14px;
		float: none;
		&:hover, &:focus {
			background-color: transparent; }
		.icon-bar {
			background-color: #fff;
			width: 40px;
			height: 4px;
			border-radius: 8px;
			margin-bottom: 8px; } } }
@media screen and (min-width: 1200px) {
	#bs-example-navbar-collapse-1 {
    	width: 80%;
    	margin: 0 auto; } }
.close-menu {
	display: none;
	cursor: pointer;
	&:hover, &:focus {
		background-color: transparent; } }
.container {
	width: 1110px; }
@media screen and (min-width: 992px) and (max-width: 1300px) {
	header {
		#logo {
			width: 200px;
			left: 20px; }
		ul.contact {
			padding-right: 0;
			li {
				a {
					font-size: 0.8em; } } } }
	#navbar {
		ul.navbar-nav > {
			li {
				a {
					font-size: 0.9em; } } } } }

@media screen and (max-width: 823px) and (min-width: 768px) {
	header {
		#navbar {
			ul.navbar-nav > {
				li {
					a {
						font-size: 0.8em; } } } } } }
@media screen and (max-width: 1110px) {
	.container {
		max-width: 1110px;
		width: 100%; } }
@media screen and (max-width: 1024px) {
	.container {
		max-width: 1024px;
		width: 100%; } }
@media screen and (min-width: 769px) and (max-width: 991px) {
	header {
		height: 160px;
		ul.contact-mobile {
			position: absolute;
			z-index: 99;
			padding: 0;
			top: 30px;
			right: 30px;
			margin: 0px;
			display: block;
			width: auto;
			li {
				a {
					text-align: right; } } }
		ul.contact {
			display: none; }
		.member_of {
			top: 150px; } } }


@media screen and (max-width: 1180px) {
	header {
		#navbar {
			.menu-holder {
				width: 100%;
				ul.navbar-nav {
					margin-top: 47px; } } } } }

@media screen and (min-width: 769px) {
	.collapse {
		display: block; } }
@media screen and (max-width: 768px) {
	body {
		background-image: none;
		background: #e1819d;
		background: -webkit-linear-gradient(left, #e1819d , #30a0b8);
		background: -o-linear-gradient(right, #e1819d, #30a0b8);
		background: -moz-linear-gradient(right, #e1819d, #30a0b8);
		background: linear-gradient(135deg, #e1819d , #30a0b8); }
	.container {
		max-width: 768px;
		width: 100%; }
	header {
		#logo {
			position: absolute;
			top: 13px;
			left: 30px;
			width: 220px;
			height: 37px; }
		.member_of, ul.contact {
			display: none; }
		ul.contact-mobile {
			display: block; }


		#navbar {
			#bs-example-navbar-collapse-1 {
				background-color: rgba(255, 255, 255, 0.9);
				margin: 0 -15px;
				height: 1500px;
				position: fixed;
				z-index: 999;
				width: 100%;
				ul.navbar-nav {
					position: static;
					width: 100%;
					padding: 30px 50px;
					top: 10px;
					height: {}
					z-index: 99;
					> li {
						display: block;
						a {
							color: #000; }
						ul {
							position: relative;
							z-index: 99;
							box-shadow: none;
							font-size: 0.75em;
							background-color: transparent; } } }
				ul.contact-mobile {
					position: static;
					margin: 0; }
				#close-menu {
					display: block;
					top: 15px;
					right: 20px;
					z-index: 999;
					cursor: pointer;
					background-image: url(../images/icons/icon_close.svg);
					height: 30px;
					width: 30px;
					background-size: contain; } } }
		.navbar-collapse {
			.collapse {
				display: none !important; } } }
	.navbar-default {
		.navbar-toggle {
			display: block;
			position: absolute;
			right: 15px; } }
	.navbar-header {
		float: none {
				right: 30px; } } }
@media screen and (max-width: 767px) {
	header {
		#logo {
			position: absolute;
			top: 13px;
			left: 15px; } } }
.rc-anchor {
	border: none;
	box-shadow: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none; }
.modal-body {
	padding-bottom: 70px;
	input {
		width: 100%;
		margin-bottom: 22px;
		padding: 10px; }
	.button {
		position: absolute;
		bottom: 15px;
		left: 38%;
		height: 40px;
		background-color: #f6a950;
		padding: 6px;
		color: white;
		font-weight: bolder;
		width: 150px;
		&:hover {
			background-color: #00b2e6; } } }
button.close {
	background-color: #f6a950;
	opacity: 1;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	color: white;
	margin: 13px 12px 0 0;
	&:hover {
		background-color: $primaryColor;
		color: white; } }
.aside-img {
	margin-bottom: -2px;
	@media screen and (max-width: 767px) {
		margin: 0 auto -2px;
		display: block; } }


@import 'footer';

@import 'modules/contact';
@import 'modules/home';
@import 'modules/article';
@import 'modules/templates';
