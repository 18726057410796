@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';
.home {
	.wrapp {
		background-image: url(../images/wrapper/bogdanovska.png);
		background-position: 75% 100px;
		background-repeat: no-repeat;
		@media (-webkit-min-device-pixel-ratio: 2),(min-resolution: 192dpi) {
			background-image: url(../images/wrapper/bogdanovska@2x.png); } } }
.home-page {
	width: 100%;
	// height: 550px
	h1 {
		font-size: 4em;
		color: white;
		margin-top: 1em;
		width: 70%; }
	.smile {
		color: white;
		white-space: nowrap;
		font-weight: 100;
		margin-top: 15px;
		display: inline-block;
		font-size: 3em; }
	.central-btn {
		margin-top: 70px;
		.order-btn {
			float: left; }
		.meet-daniela {
			display: inline-block;
			margin: 20px;
			color: white;
			border-bottom: 1px solid;
			padding-bottom: 2px;
			font-size: 20px;
			font-weight: 700;
			float: left;
			&:hover, &:focus {
				text-decoration: none;
				color: $primaryColor;
				-webkit-transition: 0.2s;
				transition: 0.2s; } } } }


@media screen and (max-width: 1300px) {
	.home {
		.wrapp {
			background-position: 98% 100px; } } }
@media screen and (max-width: 991px) and (min-width: 769px) {
	.home {
		.wrapp {
			background-position: 98% 100px;
			background-size: 300px; } } }
@media screen and (max-width: 768px) {
	.home-page {
		.central-btn {
			.order-btn, .meet-daniela {
				float: none;
				margin: 0 auto; }
			.meet-daniela {
				display: block;
				border: none;
				text-align: center;
				text-decoration: underline;
				margin-top: 12px; } }
		h1 {
			font-size: 2em; }
		.smile {
			font-size: 1.5em; } }
	.home {
		.wrapp {
			background-image: url(../images/wrapper/bogdanovska.png);
			height: 400px;
			background-position: 90% 200%;
			background-size: 170px; } } }

