@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

.contact {
	.wrapp {
		background-image: url(../images/wrapper/bogdanovska.png);
		background-position: 75% 100px;
		background-repeat: no-repeat;
		@media screen and (max-width: 1540px) {
			background-position: 98% 100px; }
		@media screen and (max-width: 1300px) {
			background-position: 98% 100px; } }
	main {
		min-height: 600px !important; }
	.contact_form {
		background-color: white;
		border-radius: 6px;
		padding: 25px;
		margin-bottom: 25px;
		.form-wrapp {
			border: 1px solid $primaryColor;
			.left-column {
				border-right: 1px solid $primaryColor; }
			.left-column,
			.right-column {
				width: 50%;
				float: left; }
			.nice-select,
			input {
				height: 45px; }
			input,
			.nice-select,
			textarea {
				border-radius: 0;
				border: none;
				box-shadow: none;
				color: $primaryColor;
				::-webkit-input-placeholder {
					color:  $primaryColor; }
				::-moz-placeholder {
					color:  $primaryColor; }
				:-ms-input-placeholder {
					color:  $primaryColor; }
				:-moz-placeholder {
					color:  $primaryColor; } }
			textarea {
				height: 135px; }
			input[type="mail"] {
				border-top: 1px solid $primaryColor;
				border-bottom: 1px solid $primaryColor; } }
		.content-contact {
			ul {
				li {
					line-height: 1.4em;
					display: block;
					height: 1.4em;
					&:nth-child(2),
					&:nth-child(3) {
						font-weight: 100; }
					a {
						font-weight: 700;
						color: $secondaryColor; } } } }

		.captcha {
			position: relative;
			background-color: #f9f9f9;
			.order-btn {
				color: white;
				font-weight: 700;
				height: 50px;
				border: 4px solid rgba(255, 255, 255, 0.8);
				position: absolute;
				top: 15px;
				right: 30px;
				width: 280px; }
			@media screen and (max-width: 1060px) {
				height: 150px;
				.order-btn {
					bottom: 12px;
					right: 12px;
					top: initial; } } }

		.heading-contact-form {
			color: $primaryColor;
			padding: 10px 0;
			font-size: 21px; } }
	footer {
		margin-top: 0; } }
.current {
	display: block;
	padding: 5px; }
.content-contact {
	line-height: 1.3em;
	h3 {
		margin: 0; }
	a:hover {
		text-decoration: none;
		color: $secondaryColor; } }

@media screen and (max-width: 768px) {
	.contact {
		.contact_form {
			.form-wrapp {
				.left-column, .right-column {
					float: none;
					width: 100%;
					border-right: none;
					.nice-select {
						border-bottom: 1px solid $primaryColor; } } }
			.captcha {
				height: 150px;
				position: static;
				.order-btn {
					position: static;
					margin: 10px;
					width: 92%; } } }
		.wrapp {
			background-position: 114% 100px; } } }
@media screen and (max-width: 767px) {
	.contact {
		.wrapp {
			background-image: none; } } }


