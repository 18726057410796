@CHARSET 'UTF-8' {}

@import 'variables';
@import 'mixins';


footer {
	background-color: white;
	min-height: 200px;
	@media screen and (min-width: 1300px) {
		border-bottom: 0;
		width: 100%; }
	.footer-wrapp {
		box-sizing: border-box;
		position: relative;
		top: -50px;
		.footer-buttons {
			box-sizing: border-box;
			float: left;
			width: 20%;
			a {
				display: block;
				margin: 0 auto;
				text-align: center;
				i {
					display: block;
					height: 105px;
					width: 105px;
					border-radius: 50%;
					background-repeat: no-repeat;
					background-position: center;
					background-size: 3.5em;
					background-color: white;
					margin: 0 auto; }
				.general {
					background-image: url(../images/icons/icon_general.svg); }
				.business {
					background-image: url(../images/icons/icon_business.svg); }
				.individual {
					background-image: url(../images/icons/icon_individual.svg); }
				.groups {
					background-image: url(../images/icons/icon_group.svg); }
				.skype {
					background-image: url(../images/icons/icon_skype.svg); }
				span {
					font-weight: 700;
					color: $primaryColor;
					font-size: 1.4em; }
				&:hover {
					text-decoration: none;
					i {
						background-size: 4em;
						-webkit-transition: 0.2s ease;
						transition: 0.2s ease; }
					span {
						color: $secondaryColor; } } } } }
	.copywrite {
		border-top: 1px solid #efefef;
		color: #bbb;
		text-align: center;
		padding: 25px 0;
		a {
			color: #bbb;
			&:hover {
				color: $primaryColor;
				text-decoration: none; } } }
	.choose-favourite, .choose-favourite-mobile {
		display: block;
		text-align: center;
		color: $primaryColor;
		opacity: 0.5;
		padding-bottom: 25px;
		font-size: 1.3em; }
	.choose-favourite {
		&:before {
			content: " ";
			display: block;
			padding-bottom: 25px;
			width: 20px;
			height: 10px;
			background-image: url(../images/icons/arrow_up_blue.svg);
			background-repeat: no-repeat;
			background-position: center;
			margin: 0 auto;
			opacity: 0.5; } }
	.choose-favourite-mobile {
		padding-top: 25px;
		padding-bottom: 0;
		display: none;
		&:after {
			content: " ";
			display: block;
			padding-bottom: 25px;
			width: 20px;
			height: 10px;
			background-image: url(../images/icons/arrow_up_blue.svg);
			background-repeat: no-repeat;
			background-position: center;
			margin: 0 auto;
			opacity: 0.5;
			-ms-transform: rotate(180deg);
			-webkit-transform: rotate(180deg);
			transform: rotate(180deg); } } }

@media screen and (max-width: 768px) {
	footer {
		.footer-wrapp {
			top: 0;
			.footer-buttons {
				float: none;
				width: 100%;
				border-bottom: 2px solid #e9f6fc;
				padding-bottom: 25px; } }
		.choose-favourite-mobile {
			display: block; }
		.choose-favourite {
			display: none; }
		.copywrite {
			font-size: 0.8125em;
			.linka {
				display: none; } } } }
