@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

.detail-page {
	margin-top: 45px; }
.learning-forms, .for-childrenm, .detail-page {
	.content {
		margin-top: 25px;
		font-weight: 100;
		h2 {
			text-align: left;
			margin: 0;
			margin-bottom: 20px;
			font-weight: 700;
			text-transform: inherit;
			color: #1269ad; }
		strong {
			color: #1b75bb; } }
	.subpage-menu-wrapp {
		border-radius: 6px;
		border-collapse: separate;
		.subpage-menu {
			padding-left: 0;
			margin-bottom: 45px;
			li {
				list-style-type: none;
				margin-bottom: 0;
				a {
					padding: 12px;
					display: block;
					color: white;
					background-color: $primaryColor;
					border-bottom: 1px dotted #1b75bb;
					font-weight: 400;
					&:hover {
						background-color: #1b75bb;
						color: white;
						text-decoration: none; } }
				.menu_level_1 {
					display: none; } }
			.active {
				a {
					background-color: #1b75bb; } } } }
	.buttons {
		margin-top: 60px;
		margin-bottom: 0;
		text-align: center;
		padding-bottom: 0;
		color: white;
		font-size: 1.2em;
		.number {
			display: block;
			height: 135px;
			width: 135px;
			border: 10px solid #efefef;
			border-radius: 50%;
			margin: 1em auto;
			background-color: $primaryColor;
			text-align: center;
			padding-top: 18px;
			font-size: 47px;
			color: white;
			padding-left: 10px;
			padding-top: 30px; }
		.col-md-4 {
			border-right: 2px solid #e3e3e3;
			padding-bottom: 46px; }
		.col-md-4:last-child {
			border-right: none; }
		ul {
			padding-left: 0;
			li {
				list-style-type: none; }
			font-weight: 100; }
		p {
			font-weight: 100;
			overflow: hidden;
			line-height: 1.1em; }
		h4, h3 {
			font-size: 1.2em; }
		h4 {
			height: auto;
			line-height: 1.8em; }
		h3 {
			font-weight: 100;
			line-height: 30px;
			margin-bottom: 25px;
			margin: 0 -12px;
			min-height: 92px; } }

	.prices {
		text-align: center;
		background-color: $primaryColor;
		height: 100px;
		.col-md-4 {
			border-right: 2px solid #e3e3e3; }
		.col-md-4:last-child {
			border-right: none; }
		.order-btn {
			margin: 1em auto; }
		.price {
			font-size: 40px;
			color: #fff;
			font-weight: 700;
			padding-top: 30px;
			display: block; } }
	.prices:last-child {
		margin-bottom: 100px;
		p {
			text-align: center;
			color: white;
			padding: 30px 0 5px 0; } }
	.short_content {
		padding: 15px 40px;
		min-height: 170px; } }
.price-mobile {
	display: none;
	background-color: $primaryColor;
	padding-bottom: 20px;
	margin-top: 30px;
	margin-left: -30px;
	margin-right: -30px;
	span {
		font-size: 40px;
		color: #fff;
		display: block;
		padding: 20px; }
	.order-btn {
		margin: 1em auto; } }
.faq {
	margin: 45px 0;
	.carts, #cart1, #cart2 {
		background-color: $primaryColor;
		border-bottom: 1px solid #0182a8;
		cursor: pointer;
		min-height: 45px;
		position: relative;
		h4 {
			color: white;
			margin: 0;
			padding: 13px 45px 13px 20px;
			font-weight: 700;
			display: inline-block; }
		i {
			display: inline-block;
			background-image: url(../images/icons/arrow_down.svg);
			height: 20px;
			width: 20px;
			background-repeat: no-repeat;
			position: absolute;
			right: 20px;
			top: 12px; } }
	.chevron_down {
		i {
			background-image: url(../images/icons/arrow_up.svg); } }

	.article-content {
		display: none;
		background-color: #00a4d4;
		border-bottom: 1px solid #0182a8;
		color: #fff;
		padding: 30px;
		font-size: 16px;
		line-height: 1.5; } }
@media screen and (max-width: 767px) {
	.prices-desktop {
		display: none; }
	.price-mobile {
		display: block; }
	.learning-forms {
		.buttons {
			padding-bottom: 0;
			.number {
				margin: 20px auto; }
			.col-md-4 {
				border-right: none; } } } }
